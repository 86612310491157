// DashboardComponent.js
import React from "react";
import TableComponent from "./TableComponent";
import GraphComponent from "./GraphComponent";
import { useState, useEffect } from "react";

const nanWrapper = (x, prefix, suffix) => {
  if (isNaN(x)) {
    return "";
  }
  return `${prefix}${x}${suffix}`;
};

const SERVER_COST = 48116.42;
const DashboardComponent = ({ s3Url }) => {
  const [graphData, setgraphData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [otherStats, setOtherStats] = useState({});

  const [totalHours, setTotalHours] = useState(0);
  const pricePerHour = 5.58;

  useEffect(() => {
    // Fetch the JSON data from the S3 URL
    console.log("fetching other stats data");
    const url = s3Url.replace("day_aggregates.json", "other_stats.json");
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("other stats data", data);
        setOtherStats(data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [s3Url]);

  const totalCostBuying = 48000 + otherStats["electricity_price"];
  const totalCostRenting = otherStats["total_rental_cost"];
  const daysTillBreakEven =
    (totalCostBuying - totalCostRenting) /
    (pricePerHour - otherStats["average_electricity_price_per_hour"]) /
    24;
  // TODO correctly compute days until break even - account for electricity
  console.log("total cost buying", totalCostBuying);
  console.log("total cost renting", totalCostRenting);
  console.log("days till break even", daysTillBreakEven);
  console.log("price per hour", pricePerHour);
  console.log(
    "average electricity price per hour",
    otherStats["average_electricity_price_per_hour"]
  );

  useEffect(() => {
    // Fetch the JSON data from the S3 URL
    // console.log("fetching data");
    fetch(s3Url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("data", data);
        data = data.map((x) => {
          return { ...x, value: x.value * 24 };
        });
        console.log("updated data", data);
        setgraphData(data.map((x) => ({ ...x, value: x.value.toFixed(2) })));
        setLoading(false);

        const totalHours = data.map((x) => x.value).reduce((a, b) => a + b, 0);
        // console.log("total hours", totalHours);

        setTotalHours(totalHours.toFixed(2));
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [s3Url]);

  console.log("other stats", otherStats);
  const tableData = [
    {
      metric: "Total Hours of Server Usage",
      value: nanWrapper(totalHours, "", " hrs"),
    },
    {
      metric: "GPU Usage %",
      value: nanWrapper(otherStats["utilization_by_hour"], "", "%"),
    },
    {
      metric: "Total Cost of Electricity",
      value: nanWrapper(otherStats["electricity_price"], "$", ""),
    },
    {
      metric: "Effective Cost Per Hour",
      value: nanWrapper(
        ((SERVER_COST + otherStats["electricity_price"]) / totalHours).toFixed(
          2
        ),
        "$",
        "/hr"
      ),
    },
    {
      metric: "Estimated number of days until break even",
      value: nanWrapper(daysTillBreakEven.toFixed(1), "", " days"),
    },
    // {
    //   metric: "Cost To Rent",
    //   value: "$5.58/hr ($5.28/hr for 3 months reserved)",
    // },
  ];

  let answer =
    totalCostBuying < totalCostRenting
      ? `Yes. $${(totalCostRenting - totalCostBuying).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })} saved so far`
      : `Not yet. $${(totalCostBuying - totalCostRenting).toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        )} lost so far`;
  if (isNaN(totalCostBuying) || isNaN(totalCostRenting)) {
    answer = "";
  }

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-3">
        Will my $48K GPU server save me money?
      </h1>
      <p>Answer: {answer}</p>
      <p>
        Note: This is currently just a demo, with dummy data. Please do not
        share it yet.
      </p>

      <p className="text-center mb-4">
        I bought my own GPU server for Deep Learning Experiments. Is this more
        or less expensive than renting cloud GPUs? This website tracks my total
        GPU usage, and computes how much I have saved or lost by buying my own
        server. See{" "}
        <a href="https://rosmine.ai/2024/06/20/grumbl-my-48k-gpu-rig/">
          more details about the server on my blog (NOTE: This is currently
          private. Please email benrosmine@gmail.com for access)
        </a>
      </p>

      {/* <CostComparison
        buying={48000 + otherStats["electricity_price"]}
        renting={otherStats["total_rental_cst"]}
      /> */}
      <h2>Detailed tracking and stats</h2>
      <div
        className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start"
        // style={{ backgroundColor: "blue", height: "100%" }}
      >
        <div
          className="col-12 col-lg-6"
          style={{
            // backgroundColor: "red",
            height: "100%",
          }}
        >
          <TableComponent data={tableData} />
        </div>

        <div
          className="col-12 col-lg-6"
          // style={{ backgroundColor: "green" }}
        >
          <GraphComponent data={graphData} />
        </div>
      </div>
      <div style={{ textAlign: "left" }}>
        <h2>Details about the server and usage computation </h2>
        <p>
          {" "}
          This server has 6x RTX 6000 ADA GPUs. This has training throughput
          comparable to 4x A100 GPUs, or 2x H100 GPUs.{" "}
        </p>
        <p>
          I define "GPU Usage" here to be the percentage of time where the gpus
          were running, averaged across all GPUs on the machine and aggregated
          at the hour level. For example, if I use 3 out of the 6 GPUs for 1
          day, and leave the other 3 idle, that will count as 50% usage for that
          day, for 12 hours of total usage.
        </p>
        <p>
          Note that "Usage" is different than "gpu utilization" as output by
          nvidia-smi. If each GPU was at 50% utilization all day long, then that
          would count as 100% usage. The point of this computation is to compare
          the cost of owning a server vs. renting cloud GPUs, not to compare to
          the theoretical maximum. Running experiments in the cloud wouldn't
          have made my code any more efficient 🙃
        </p>
        <p>
          For comparing the price of renting vs owning, I am calculating at an
          hour level granularity, to mimic how I would use a cloud provider
          (e.g. if I run an experiment for half an hour, code up a new
          experiment for 30 minutes, then start the new experiment, I would not
          stop and restart the cloud instance). For those interested in minute
          level granularity, I calculated the average usage at the minute level
          to be {otherStats["utilization_by_minute"]}%.
        </p>
        <p>
          "Effective Cost Per Hour" is equal to the cost to build the server
          plus total cost on electricity, divided by the total hours of server
          usage
        </p>
        <p>
          The total cost to rent is calculated using historical data for cloud
          costs. The estimated number of days until break even uses the current
          cloud cost of renting a similar machine. The total cost to build the
          PC originally was ${SERVER_COST}.
        </p>
        <p>
          P.S. I put ads on this site as an experiment to see if that could
          cover any of the GPU costs. I'm sorry if they are annoying.
        </p>

        {/* <p>
          For more details about the computation, see package on github (tk
          link)
        </p> */}
        {/* <p>
          On my server, I have a cron job that logs the utilization (gpu-util)
          of each GPU every minute (TODO link to github){" "}
        </p>

        <p>
          To count the total hours of server usage, I use the total number of
          minutes from these logs where the gpu utilization is above 0% (summed
          over all 6 GPUs), then divide by 60 to convert to hours, then divided
          by 6 because I have 6 GPUs (Note this is counting server usage hours,
          not GPU hours)
        </p>

        <p>
          Example 1: If I have 3 GPUs inactive, and 3 GPUs running at 100%
          utilization for 1 hour. This would count as .5 hours total usage,
          since only half the GPUs were running. If this were in the cloud, I
          could've saved money by using a machine with fewer GPUs.
        </p>

        <p>
          Example 2: If I have all 6 GPUs running at 50% utilization for 1 hour,
          this would still count as a full hour of usage.{" "}
        </p>

        <p>
          The reason behind this decision is because I want to do as close of a
          comparison of using a local server vs. a cloud server. The code would
          have the same utilization no matter where it runs.{" "}
        </p>
        <p>
          For reference, common utilization rates are below 15% for a single PhD
          student, above 35% for a PhD student slurm cluster, and above 60% for
          a company-wide slurm research cluster{" "}
          <a href="https://timdettmers.com/2023/01/30/which-gpu-for-deep-learning/#:~:text=common%20utilization%20rates">
            {" "}
            Source
          </a>
        </p> */}
      </div>
    </div>
  );
};

export default DashboardComponent;
