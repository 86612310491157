// TableComponent.js
import React from "react";

const TableComponent = ({ data }) => {
  return (
    <div className="card" style={{ height: "100%" }}>
      <div
        className="card-body"
        style={{ paddingTop: 0, paddingBottom: 0, height: "100%" }}
      >
        <table className="table" style={{ marginBottom: 0, height: "100%" }}>
          <thead>
            <tr>
              <th scope="col">Metric</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                className={index === data.length - 1 ? "table-active" : ""}
              >
                <td>{row.metric}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
